import * as React from "react"
import { Link } from "gatsby"
import SearchEngineOptimisation from "../components/SearchEngineOptimisation"
import Hero from "../components/Hero"
import Footer from "../components/Footer"
import '../styles/styles.scss'

const PrivacyPage = () => {
    return (
        <>
            <main>
                <SearchEngineOptimisation title="Privacy & Cookie Policy" keywords={"privacy policy cookie"} path="/privacy" index={false} />

                <Hero />
                <div className="legal container">
                    <Link to='/'>{'<'} Back to app</Link>
                    <h1>Privacy Policy:</h1>
                    <p>Within this app we do not hold onto any personal information and we do not store any personally identifiable information on our users.</p>
                    <h2>Data Policy:</h2>
                    <p>With you consent you can submit your email to be notified on the release of new apps and software. By submitting your email you consent to us holding your data.</p>
                    <p>When you submit your email your email will be stored in MailChimp. You can request to have your data deleted by messaging me, or a simplier way would be to unsubsribe from the emails you have received from us.</p>
                    <h2>Cookie Policy:</h2>
                    <p>We do use cookies in order to improve this app.</p>
                    <p>A cookie is a piece of data that is stored within your browser which helps us build analytical data to help us improve our apps and services.</p>
                    <p>Not all cookies are born equal, and the cookies used on our sites are not personally identifiable to you.</p>
                    <p>These are the cookies we use:</p>
                    <code>
                        cookies: '_ga, _gat, _gid' - are used by Google Analytics.<br />
                        cookie: '__cfduid' - is used by our load balancer CloudFlare.
                    </code>
                </div>

            </main>
            <Footer />
        </>
    )
}

export default PrivacyPage